.card-vehicle{
    border-width: 2px; 
    border-style: solid;
    border-color: var(--company-color);
    /* box-shadow: rgba(72, 0, 209, 0.2) 0px 0px 2px 2px; */

}

.card-vehicle:hover {
    /* box-shadow: rgba(72, 0, 209, 0.3) 0px 0px 5px 5px; */
    transform: scale(1.05);
    cursor: pointer;
    /* transform: scale(1.05) rotate(-1deg); */
    /* box-shadow: rgba(72, 0, 209, 0.56) 0px 22px 70px 4px; */
}

.card-vehicle-selected {
  box-shadow: rgba(72, 0, 209, 0.3) 0px 0px 5px 5px;
  transform: scale(1.05);
  cursor: pointer;
  /* transform: scale(1.05) rotate(-1deg); */
  /* box-shadow: rgba(72, 0, 209, 0.56) 0px 22px 70px 4px; */
}

.card-header {
    border-radius: 10;
}


.card-button{
  background-color: var(--company-color);
  border-radius: 100px;
  /* box-shadow: rgba(72, 0, 209, .2) 0 -25px 18px -14px inset,rgba(72, 0, 209, .15) 0 1px 2px,rgba(72, 0, 209, .15) 0 2px 4px,rgba(72, 0, 209, .15) 0 4px 8px,rgba(72, 0, 209, .15) 0 8px 16px,rgba(72, 0, 209, .15) 0 0px 0px; */
  color: white;
  cursor: pointer;
  display: inline-block;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.card-button:hover {
  /* box-shadow: rgba(72, 0, 209, .2) 0 -25px 18px -14px inset,rgba(72, 0, 209, .15) 0 1px 2px,rgba(72, 0, 209, .15) 0 2px 4px,rgba(72, 0, 209, .15) 0 4px 8px,rgba(72, 0, 209, .15) 0 8px 16px,rgba(72, 0, 209, .15) 0 16px 32px; */
  transform: scale(1.05) rotate(-1deg);
}