.form-stepper-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-stepper-form-div {
    align-self: center;
    width: 100%;
}

.form-stepper-form-element{
    width: 75%;
    margin: auto;
}

.form-stepper-form-separator{
    margin-top: 40px;
}

.form-stepper-buttons-div {
    justify-content: center;
    align-content: center;

}

.form-stepper-button-prev {
    margin-right: 16px;

}

.company-pri-btn{
    background-color: var(--company-color) !important;
    color: #fff !important;
}

.company-sec-btn{
    color: var(--company-color);
    background-color: #d6dde4;
}

.menu-btn{
    width: 50%;
}


.form-stepper-button-next {
  background: var(--company-color);
  border-radius: 999px;
  /* box-shadow: #5E5DF0 0 10px 20px -10px; */
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  opacity: 1;
  outline: 0 solid transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.form-stepper-button-prev {
    background: #827f82;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    opacity: 1;
    outline: 0 solid transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
  }
.form-stepper-header{
    width: 100%;
}

.resume {
    border:1px solid black;
}

.k-step-indicator:hover, .k-step-link:hover > .k-step-indicator{
    background-color: var(--company-color) !important;
}