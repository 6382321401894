.step4-contact-info-header {
    color: gray;
}

@keyframes append-animate {
	from {
		transform: scale(0);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;	
	}
}

.step4 {
	animation: append-animate .3s linear;
}
.step4-identification-number {
	animation: append-animate .3s linear;
	border-radius: 16px;
}
.title-section {
    color:var(--company-color) ;
}


