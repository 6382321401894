.k-expander-header {
  background-color: #4800d1;
}

.k-header {
  background-color: #fff;
  border: none;
  text-align: center;
}


/* Tabla Transpuesta */
/* .k-grid-header {
  float: left;
  padding: 0 !important;
}

.k-grid-content {
  float: right;
}

.k-grid {
  display: flex;
}

table {
  width: auto;
  white-space: nowrap;
}

tr {
  display: inline-block;
}

thead tr {
  display: inline;
}

th,
td {
  display: block;
  border: 1px solid black;
  height: 30px;
  padding: 15px;
} */

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

#root {
  width: 99%;
  color: #3c4858;
}
.k-step-indicator{
  display: block !important;
}

.k-step-indicator-icon.k-icon.k-i-check{
  display: block !important;

}

/* correction card body */

div.card-body {
  padding: 1.25rem !important;
}


.k-grid-header-wrap > table {
  width: 100% !important;
}

.k-grid-table {
  width: 100% !important;
}

.k-expander-title {
  color: #fff;
}

.agm-primary-color {
  color: #4800d1;
}

.k-expander-header:hover {
  background-color: #4800d1;
  transform: opacity 0.5s;
}

.form-risk {
  position: relative;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.form-risk:hover {
  transform: scale(1.1);
}

.cr {
  width: 100%;
  padding: 10px;
  position: absolute;
  z-index: 100;
  overflow: hidden;
  text-align: center;
  color: #f0f0f0;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(253, 75, 89, 0.8211659663865546) 40%,
    rgba(2, 0, 36, 1) 100%
  );
}

/* Positions */

.cr-top {
  top: 10px;
}
.cr-bottom {
  bottom: 25px;
}
.cr-left {
  left: -50px;
}
.cr-right {
  right: -60px;
}

/* Rotations */

.cr-top.cr-left,
.cr-bottom.cr-right {
  transform: rotate(-45deg);
}

.cr-top.cr-right,
.cr-bottom.cr-left {
  transform: rotate(45deg);
}

/* Colors */

.cr-white {
  background-color: #f0f0f0;
  color: #444;
}
.cr-black {
  background-color: #333;
}
.cr-grey {
  background-color: #999;
}
.cr-blue {
  background-color: #39d;
}
.cr-green {
  background-color: #2c7;
}
.cr-turquoise {
  background-color: #1b9;
}
.cr-purple {
  background-color: #95b;
}
.cr-red {
  background-color: #e43;
}
.cr-orange {
  background-color: #e82;
}
.cr-yellow {
  background-color: #ec0;
}
